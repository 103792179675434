import request from "../utils/request";
import method from "vue-resource/src/http/interceptor/method";
import * as url from "url";
import qs from 'qs'
/**
 * 每日收益
 */
export const getEverydayProfit=(date_type)=>request({
    method:'GET',
    url:'/statistics/getUserProfitAndTradeCount',
    params:{date_type,requestTimes:10}
})


/**
 * 获取我的收益信息
 */
export const getProfitInfo=()=>request({
    method:'GET',
    url:'/statistics/userProfitByAll',
})
/**
 * 获取本月收益统计图
 */
export const getMonthProfitEchart=(data)=>request({
    method:'GET',
    url:'/statistics/userProfitChartsByMonth',
    params:data
})
/**
 * 获取总收益统计图
 */
export const getAllProfitEchart=()=>request({
    method:'GET',
    url:'/statistics/userProfitCharts',
})

/**
 * 获取我的排行
 */
export const getMyRank=()=>request({
    method:'GET',
    url:'/statistics/getMyRank',

})
/**
 * 获取总排行榜前10
 */
export const getProfitTop10=()=>request({
    method:'GET',
    url:'/statistics/userProfitTop10',
})

/**
 * USDT和rmb汇率
 */
export const getUSDTRateByCode=()=>request({
    method:'GET',
    url:'/statistics/getUSDTRateByCode',
})
/**
 * 走势图,获取总收益
 */
export const getUserProfitLine=()=>request({
    method:'GET',
    url:'/statistics/userProfitLine',
})

export const getUserActivity=(activityId)=>request({
    method:'post',
    url:'/vip/getUserActivity',
    params:{activityId},
})

export const addUserActivity=(activityId)=>request({
    method: 'post',
    url: '/vip/addUserActivity',
    params: {activityId},
})

/**
 * 获取活动信息
 * @param id
 * @returns {AxiosPromise}
 */
export const getActivityById = (id) => request({
    method: 'POST',
    url: '/vip/getActivityById',
    params: {id}
})

/**
 * 获取到期时间
 * @returns {AxiosPromise}
 */
export const getUserPolicy = (requestTimes) =>request({
    method: 'POST',
    url: '/vip/getUserPolicy',
    params: {requestTimes}
})


/**
 * 修改用戶暱稱
 */
export const editUserName=(nickName)=>request({
    method: 'post',
    url: '/index/editNickName',
    params: {nickName},
})
/**
 * 修改用戶護照
 */
export const editUserPassport=(passport)=>request({
    method: 'post',
    url: '/index/editPassport',
    params: {passport},
})
/**
 * 修改用戶生日
 */
export const editUserBirth=(birthday)=>request({
    method: 'post',
    url: '/index/ediBirthday',
    params: {birthday},
})

/**
 * 修改用戶头像
 */
export const editUserAvatar=(user_face)=>request({
    method: 'post',
    url: '/index/editUserFace',
    headers: { "Content-Type": "multipart/form-data" },
    data: user_face,
})

/**
 * 解綁手機或者郵箱
 */
export const userUnEmailOrPhone=(clear_type,vcode)=>request({
    method:'get',
    url:'/index/clearPhoneOrEmail',
    params:{
        clear_type,vcode
    }
})

/**
 * 获取每日收益统计
 * @param year
 * @param month
 * @returns {AxiosPromise}
 */
export const userProfitTableByMonth = (params) =>request({
    method: 'POST',
    url: '/statistics/userProfitTableByMonth',
    params: params
})

/**
 * 获取每日收益明细
 * @param params
 * @returns {AxiosPromise}
 */
export const getDayProfitByUserAndDate = (params) => request({
    method: 'POST',
    url: '/statistics/getDayProfitByUserAndDate',
    params: params
})
